import { useEffect, useState } from "react";
import calculateResult from "../../../utils/AIEnabledCamera/calculateResults";
import {
  LineIcon,
  WhatsappIcon,
  LineShareButton,
  WhatsappShareButton,
} from "react-share";
import stand4strength from "../../../assets/Vectors/PNG/stand4strength_banner.png";
import Vn_stand4strength from "../../../assets/Vectors/PNG/vn_stand4strength_banner.png";

import stand4strengthVN from "../../../assets/Vectors/SVG/ic_result_vn_logo.svg";
import { useTranslation } from "react-i18next";
import {
  event,
  eventWithParameters,
} from "../../../utils/gaStatistics/gaEvent";
import usePageTracking from "../../../hooks/useTracking";
import { MUSCLE_AGE_RESULTS } from "../../../utils/constants/internalUrls";
import { useLocation, useNavigate } from "react-router-dom";
import { ART_BOARD_PNG } from "../../../utils/constants/externalUrls";
import { FEMALE, MALE } from "../../../utils/constants/constants";

import { Icon } from "@iconify/react";
import i18next from "i18next";
import ShareButton from "./shareButton";
import calculateSingaporeResult from "../../../utils/AIEnabledCamera/calculateSingaporeResults";

const MuscleAgeResults = ({
  age,
  gender,
  time,
  isFullScreen,
  setFullScreen,
  cameraAccess,
}) => {
  const [isOnline, setIsOnline] = useState(true);
  const [output, setOutput] = useState({
    outputAge: 0,
    utter: "",
    footnote: "",
    percentileResult: {},
  });

  const navigate = useNavigate();
  const { t } = useTranslation();
  const location = useLocation();
  const { language } = i18next;

  const isTh = language === "th";
  const isVn = language === "vn";
  const isEn = language === "en";
  const isIn = language === "in";

  const searchParams = new URLSearchParams(location.search);
  usePageTracking(MUSCLE_AGE_RESULTS);

  //! Check for network connection
  useEffect(() => {
    if (!navigator.onLine) {
      setIsOnline(false);
    }
  }, [navigator]);

  useEffect(() => {
    postMessage({ resultsUpdated: [gender, age, time, output] }, "*");

    window?.localStream?.getVideoTracks()[0]?.stop();
    if (isFullScreen) {
      setFullScreen(false);
      // Exit fullscreen
      try {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitExitFullscreen) {
          document.webkitExitFullscreen();
        }
      } catch (Error) {
        console.error(
          "An error occurred while using exitFull screen button, The error is:",
          Error
        );
      }
    }
  }, []);

  useEffect(() => {
    const urlAge = searchParams.get("age");
    const urlGender = searchParams.get("gender");
    const urlTime = searchParams.get("time");
    let result;

    if (urlAge && urlGender.includes(MALE, FEMALE) && urlTime) {
      result = isEn
        ? calculateSingaporeResult(urlTime, urlAge)
        : calculateResult(urlGender, urlTime, urlAge);
    } else if (age && gender && time) {
      result = isEn
        ? calculateSingaporeResult(time, age)
        : calculateResult(gender, time, age);
    }

    setOutput(result);

    if (result) {
      let eventName, parameters;

      if (isTh || isVn) {
        eventName = "muscle_age_calculator_completed";
        parameters = {
          event_action: "test_completed",
          event_label:
            result.outputAge === "+80" ? "\u200b+80" : result.outputAge,
        };
        eventWithParameters(eventName, parameters);
      } else if (language === "au") {
        eventName = "muscleAgeCalculatorComplete";
        parameters = {
          type: cameraAccess ? "withCamera" : "withoutCamera",
        };
        eventWithParameters(eventName, parameters);
      }
    }
  }, [language]);

  const handleShareButtonClick = (platform) =>
    event("Share Results", "Social share", platform, 1, false);

  /* <--------------UI Start Point--------------> */

  const socialMedia = () => {
    return (
      <div className="flex flex-col justify-center">
        <div className="mb-2 text-lg font-bold uppercase ">
          {t("results.shareResults")}
        </div>
        <div className="flex justify-center gap-1 ml-2">
          {["en", "my", "au"].includes(language) && (
            <ShareButton Platform={WhatsappShareButton} output={output}>
              <WhatsappIcon className="rounded-[100%]" size={32} />
            </ShareButton>
          )}
          {["en", "th"].includes(language) && (
            <ShareButton
              Platform={LineShareButton}
              onClick={() => handleShareButtonClick("Line")}
              output={output}
            >
              <LineIcon size={32} round={true} />
            </ShareButton>
          )}
        </div>
      </div>
    );
  };

  //! Main ui
  return (
    <div
      className={`landing ${isVn ? "font-san" : "font-brandon"}`}
      id="share-button"
    >
      <div className="container justify-center mx-auto lg:h-screen">
        <div className="flex flex-col items-center justify-center max-w-3xl mx-auto lg:h-full lg:pt-0 ">
          {isVn ? (
            <img
              src={Vn_stand4strength}
              alt="Stand 4 Strength"
              className="mx-auto w-28 mt-28 lg:mt-0 lg:w-56 lg:mx-0"
            />
          ) : (
            <img
              src={stand4strength}
              alt="Stand 4 Strength"
              className="w-56 mx-auto mt-28 lg:mt-0 lg:w-80 lg:mx-0"
            />
          )}
          <div className="relative z-10 flex flex-col p-4 mx-5 mt-4 bg-white rounded-lg shadow-lg lg:p-8 lg:mt-12">
            {/* Back icon */}
            <Icon
              onClick={() => navigate("/")}
              className="z-20 cursor-pointer"
              icon="fluent-mdl2:navigate-back"
              color="#000000"
              width="30"
              alt="Back button and go to the retest page"
            />

            <div className="flex flex-col text-center">
              <div className="flex flex-col">
                <map name="image-map">
                  <area
                    target="_self"
                    alt="Muscle age calculator text"
                    title="Muscle age calculator text"
                    href=""
                    coords="59,573,600,814"
                    shape="rect"
                  />
                  <area
                    target="_parent"
                    alt="A man sitting on a chair"
                    title="A man sitting on a chair"
                    href=""
                    coords="6,108,642,477"
                    shape="rect"
                  />
                </map>
                <img
                  className="mx-auto mb-4 w-36"
                  alt="Muscle age calculator"
                  src={isVn ? stand4strengthVN : ART_BOARD_PNG}
                  usemap="#image-map"
                />
                {!output ? (
                  <div className="flex flex-col my-1 text-xl font-bold lg:text-2xl gap-7">
                    {t("results.invalidLinkOrUnsupportedProcess")}
                    <button
                      onClick={() => navigate("/")}
                      className="px-4 py-2 font-bold text-white rounded bg-blueColor hover:bg-blueColor hover:bg-opacity-80 "
                    >
                      Please Try Again
                    </button>
                  </div>
                ) : (
                  <>
                    <div
                      className={`mb-10 text-2xl lg:text-3xl ${
                        isVn && "uppercase mt-2.5"
                      }`}
                    >
                      <span
                        className={`text-2xl lg:text-3xl ${
                          isTh ? "font-thin" : isVn ? "relative" : "font-medium"
                        }`}
                      >
                        {t("results.output")}
                      </span>
                      <span className="font-bold">{output.outputAge}</span>
                      <span className="font-bold">{t("results.year")}</span>
                    </div>
                    <div className="text-xl font-bold ">
                      {t(`results.${output.utter}`)}
                    </div>
                    <hr className={`my-6 lg:my-7 ${isVn && "hidden"}`} />
                    {isOnline && !isVn && !isIn ? socialMedia() : ""}
                    <div className={`mt-8 text-sm ${isVn && "hidden"}`}>
                      {!isEn && (
                        <div>
                          {output.percentileResult &&
                            t(
                              `results.${output.percentileResult?.message}`,
                              output.percentileResult?.outputAge && {
                                outputAge: output.percentileResult?.outputAge,
                              }
                            )}
                        </div>
                      )}
                      <div>
                        {isEn
                          ? t(`results.${output.footnote.message}`, {
                              actualAge: age,
                              outputAge: output.outputAge,
                              outputAgeRange: output.footnote.outputAgeRange,
                            })
                          : t(`results.${output.footnote}`, { actualAge: age })}
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MuscleAgeResults;
