/*! **********************************************************************************************
 * Display a 5-second countdown timer, counting down and counting forward.
 *
 *
 *
 * Abbott copyright 2023
 ************************************************************************************************/

import { useEffect, useState } from "react";
import i18n from "i18next";
import audioTestStart from "./../../../assets/audio/English/VO_challenge_start_now_En.mp3";
import audioTestStart_thai from "./../../../assets/audio/Thailand/VO_challenge_start_now_Th.mp3";

const CountDownTimer = ({ setShowSquatTimer, createNewAudio }) => {
  const [timeLeft, setTimeLeft] = useState(5);
  const [displayedText, setDisplayedText] = useState(5);
  const { language } = i18n;
  // Play the audio when the component mounts
  useEffect(() => {
    createNewAudio(language === "th" ? audioTestStart_thai :language === "vn" ?"": audioTestStart);
  }, []);

  useEffect(() => {
    try {
      const countdownInterval = setInterval(
        () => {
          // Update the timeLeft state based on the previous value
          setTimeLeft((prevTimeLeft) =>
            prevTimeLeft > 0 ? prevTimeLeft - 1 : prevTimeLeft
          );
          setDisplayedText((prevTimeLeft) =>
            prevTimeLeft > 1 ? prevTimeLeft - 1 : "Go"
          );
        },
        language === "th" ? 920 : 1340
      );
      // Clean up the interval
      return () => {
        clearInterval(countdownInterval);
      };
    } catch (error) {
      console.log(error, "An error has occurred in countDownTimer component ");
    }
  }, []);

  useEffect(() => {
    try {
      const showTimerTimeout = setTimeout(() => {
        setDisplayedText("Go");
      }, 6100);

      // Clean up the timeout
      return () => {
        clearTimeout(showTimerTimeout);
      };
    } catch (error) {
      console.log(error, "An error has occurred in countDownTimer component");
    }
  }, []);

  // Set showSquatTimer after the countdown
  useEffect(() => {
    if (timeLeft === 0) {
      try {
        const showTimerTimeout = setTimeout(() => {
          setShowSquatTimer(true);
        }, 1000);

        // Clean up the timeout
        return () => {
          clearTimeout(showTimerTimeout);
        };
      } catch (error) {
        console.log(error, "An error has occurred in countDownTimer component");
      }
    }
  }, [timeLeft]);

  return (
    <p className="text-[100px] absolute font-extrabold font-brandon drop-shadow-lg text-blueColor">
      {displayedText}
    </p>
  );
};

export default CountDownTimer;
