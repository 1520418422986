import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import ReactPlayer from "react-player";
import { MUSCLE_CALCULATOR_VIDEO } from "../../utils/constants/externalUrls";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  maxWidth: "1200px",
};

export default function VideoModal({ open, setOpen }) {
  return (
    <div>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <ReactPlayer
            url={MUSCLE_CALCULATOR_VIDEO}
            controls={true}
            width="100%"
            height="100%"
          />
        </Box>
      </Modal>
    </div>
  );
}
