/***********************************************
 * top_down⬆⬇
 * Component: HomeOverview
 * Description: Muscle Age Insights
 * _UI
 * Copyright:
 * (c) Abbott 2023
 **********************************************/
import i18n from "i18next";
import { Trans, useTranslation } from "react-i18next";
import stand4strength from "./../../assets/Vectors/PNG/stand4strength_banner.png";
import Vn_stand4strength from "./../../assets/Vectors/PNG/vn_stand4strength_banner.png";

// ==============================
// start component
// ==============================
const HomeOverview = () => {
  const { t } = useTranslation();
  const { language } = i18n;
  const isVn = language === "vn";

  /* <--------------style helper --------------> */
  const titleClasses = [
    "text-white",
    isVn
      ? "font-san font-bold uppercase"
      : "font-brandon font-bold",
    language === "th" ? "font-thin" : "",
    "mb-14",
    isVn
      ? "font-san text-2xl xl:text-[50px] lg:text-[42px]"
      : "font-brandon",
    ["th", "fr"].includes(language)
      ? "text-3xl lg:text-6xl"
      : !isVn
      ? "text-5xl lg:text-8xl"
      : "",
  ]
    .filter(Boolean)
    .join(" ");

  // ==============================
  // _UI
  // ==============================
  return (
    <div
      className={`text-center lg:text-left flex flex-col justify-center max-w-2xl mt-16 lg:mt-0 ${
        isVn ? "font-san" : "font-brandon"
      }`}
    >
      <img
        src={language==="vn"?Vn_stand4strength:stand4strength}
        alt="Stand 4 Strength"
        className="mb-4 lg:mb-12 w-40 lg:w-80 mx-auto lg:mx-0"
      />
      <div
        className={`${(isVn && window.innerWidth < "1024") ? "text-3xl" : ""} ${titleClasses}`}
        style={
          isVn && window.innerWidth > "1024"
            ? { lineHeight: "85px" }
            : {}
        }
      >
        {isVn ? 
        <div>
          <p>{t("homePage.muscleAgeTitle.title1")}</p>
          <p className="mt-4">{t("homePage.muscleAgeTitle.title2")}</p>
        </div>:
          t("homePage.muscleAgeTitle")}
      </div>
      <div className="text-xl text-white mb-4 font-bold uppercase ">
        {t("didYouKnow")}
      </div>
      <div className="text-base text-white lg:text-2xl ">
        <Trans
          i18nKey={"homePage.atrophy"}
          components={{ superScript: <sup className="sups" /> }}
        />
      </div>
      {
        language!=="vn"&&

        <div className="text-base text-white mt-12 max-lg:text-[12px] lg:text-[16px]">
        <Trans
        i18nKey={"homePage.startYourMuscleAge"}
        components={{ superScript: <sup className="sups" /> }}
      />
        </div>
      }
      
      <div className="text-base text-white mt-12 lg:text-2xl">
        {t("homePage.exercise")}
      </div>
      {language === "au" ? (
        <div className="text-base text-white mt-8 text-1xl lg:text-1xl">
          <div>{t("reference")}</div>
          <div>{t("reference2")}</div>
          <div className="mt-4">ANZ.2024.52988.ENS.1</div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default HomeOverview;
// ==============================
// top_down⬆⬇
// ==============================
