const auTranslation = {
  translation: {
    iosAlert:
      "IOS and Mac users should navigate to the 'Settings' section of their browser and tap on the 'Add to Home Screen' option to install the software.",
    installDescription:
      "Install the app on your device to easily access it anytime. No app store. No download. No hassle.",
    homePage: {
      muscleAgeTitle: "Discover your muscle age",
      exercise:
        "Take the 1 minute Sit to Stand Challenge to discover your muscle age now!",
      atrophy:
        "After the age of 40, you can lose up to 15% of muscle mass per decade<superScript>[1]</superScript>.",
      selectYourAge: "Select your age",
      selectGender: "Select your gender",
      male: "Male",
      female: "Female",
      muscleAge: "See Your Muscle Age",
      limitation:
        "This tool is only applicable for individuals over 40 years of age",
      muscleAgeAccessCamera: "Accessing the Camera...",
      startYourMuscleAge:
        "It is important to monitor and maintain your muscle strength to help reduce the risk of falls and to continue living a healthy and active life. That's why we developed the Sit to Stand Challenge - based on the 5-time Sit to Stand<superScript>[2]</superScript>, to help indicate the strength and physical performance of your lower limbs. The innovative digital Live Session is designed to use your computer's camera to help guide you through the Challenge and determine your muscle age.",
    },

    cameraFallback: {
      camera: "No Camera? No Problem!",
      encouraging:
        "Time yourself doing the Chair Stand Challenge and input your results in the form to see what your muscle age is!",
      getStartExplain:
        "After the age of 40, you can lose up to 15% of muscle mass every 10 years!",
      motivating: "Prepare for the Challenge",
      completeTestRep: "Complete a Test Repetition",
      explanatory:
        "To complete the Chair Stand Challenge, you will need to find a chair you can use to sit down and stand up in.",
      stepByStep:
        " To perform a Chair Stand repetition, with your arms folded across your chest, you need to sit in the chair and stand up as fast as you can to complete the 5 repetitions.",
      instructive:
        "When you're ready, time how quickly you can complete the 5 Chair Stand repetitions. When you're done, input your total time below to get your muscle age!",
      inputTestTime: "Input your Test Time (in seconds)",
      yourTime: "Your test time",
      calculateMuscle: "Calculate My Muscle Age!",
      startChallenge: "Start the Challenge",
    },

    userPreparation: {
      chair: "Place a chair 1 meter in front of the camera",
      wait: "Please wait while the AI model is loading",
      notVisibleKnees:
        "Make sure your <bold>knees & shoulders </bold> are visible!",
      nextStep: "Contine to the next step",
      description: "Watch the Chair Stand Challenge instructional video",
      startTheChairStandTest: "Start the Chair Stand Challenge",
    },

    SquatTest: {
      1: "Great! 4 more repeitions to go!",
      2: "",
      3: "Perfect! You're almost halfway there!",
      attach: "2 more reps to go!",
      4: "Last one!",
      default: "Complete the 5 repetitions as quick as you can!",
    },

    testCompletionMessage: {
      congratulation: "You completed the Chair Stand Challenge!",
      processing: "Now calculating your muscle age...",
    },

    results: {
      shareResults: "Share Your Results",
      utterGood:
        "Great job! Looks like your legs are stronger than average for your age group. Keep doing what you're doing",
      utterBad:
        "OH DEAR! Your muscles need help. It's time to focus on building your muscle mass and strength.",
      utterOnPar:
        "Good job! Looks like your legs are as strong as is expected for your age group. Consider speaking with your doctor if you are interested in building or maintaining your muscle strength.",

      output: "Your muscle age is in the: ",
      footNoteFaster75:
        "Your result is faster than 75% of your peers between the {{actualAge}} age range.",
      footNoteOnPar:
        "Your result is within 25%-75% of your peers between the {{actualAge}} age range.",
      footNoteSlower75:
        "Your result is slower than the norm for your age, and slower than the mean speed for the {{actualAge}} age range.",

      footNoteFaster80:
        "Your result is faster than 75% of your peers aged 80+.",
      footNoteOnPar80: "Your result is within 25%-75% of your peers aged 80+.",
      footNoteSlower80:
        "Your result is slower than the norm for your age, and slower than the mean speed for the 80s.",

      percentileBelow25:
        "Your result is below the 25th percentile for the age category of 40-44.",
      percentileAbove25:
        "Your result is equal or above the 25th percentile for the age category of {{outputAge}}.",
      percentileBelow75:
        "Your result is equal or below the 75th percentile for the age category of {{outputAge}}.",

      unsupportedGroup: "unsupported group",
      invalidLinkOrUnsupportedProcess:
        "This link is broken or the process is incorrect, please try again!",
      year: " years old",
    },

    manualTest: {
      manualbutton:
        "No Camera? No problem! You can time yourself doing the Chair Stand Challenge and input your time into the calculator manually.<bold> Participate in the challenge without a camera here</bold>",
      EnterDetails: "Enter your details in the muscle age calculator below.",
      Step3: "Step 3",
      age: "Age",
      gender: "Gender",
      timeInSeconds: "Time in Seconds*",
      submit: "SUBMIT",
      sitToStand: "Sit-to-Stand Time (e.g. 7.5 seconds)",
      Title: "Regain Strength, Stay Active",
      forYourOwnSafety: "For Your own safety",
      forYourOwnSafetyList: {
        1: "Be careful not to strain or injure yourself.",
        2: "Stop challenge immediately if your knees start to hurt.",
        3: "Don’t lean back to gain momentum or you may topple backwards.",
        4: "Do the test when you have a friend or family member around to support you.",
        5: "Do not try the test if you have had a recent fall or injury.",
      },
    },

    calculation:
      "The muscle age calculator is based on the reference of the 5–time chair stand test that can be an indication of the strength and physical performance of the muscles in the lower limbs.[2]",
    reference:
      "[1] Baier S, et al. JPEN. 2009; 33:71–82. 2. Dodds RM, et al. J Cachexia Sarcopenia Muscle. 2021;12(2):308–318.",
    reference2: "[2] 5-chair stand test as per paper",
    didYouKnow: "DID YOU KNOW?",
    understand: "I understand",
    safety_notes: "Safety Notes",
  },
};

export default auTranslation;
