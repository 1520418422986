import stand4strength from "./../../assets/Vectors/PNG/stand4strength_banner.png";
import Vn_stand4strength from "./../../assets/Vectors/PNG/vn_stand4strength_banner.png";
import guidlineImage_en from "./../../assets/Vectors/PNG/guidline/en.png";
import guidlineImage_fr from "./../../assets/Vectors/PNG/guidline/fr.png";
import guidlineImage_vn from "./../../assets/Vectors/PNG/guidline/vi_2.png";
import guidlineImage_th from "./../../assets/Vectors/PNG/guidline/thai.png";
import guidlineImage_my from "./../../assets/Vectors/PNG/guidline/maly.png";
import guidlineImage_au from "./../../assets/Vectors/SVG/guidline/ic_AU.svg";
import i18next from "i18next";
import { useTranslation } from "react-i18next";

//! Start component
const LeftPanel = () => {
  //! Hooks
  const { language } = i18next;
  const isVn = language === "vn";
  const { t } = useTranslation();

  //! Translate of banner
  let banner = () => {
    return isVn
      ? guidlineImage_vn
      : language === "my"
      ? guidlineImage_my
      : language === "fr"
      ? guidlineImage_fr
      : language === "th"
      ? guidlineImage_th
      : language === "au"
      ? guidlineImage_au
      : guidlineImage_en;
  };

  //! List of options at the "For your own safety" section- ---> LOGIC
  const listOptions = Array.from(
    { length:language==="vn"? 4 : 5 },
    (_, i) => `manualTest.forYourOwnSafetyList.${i + 1}`
  );
  //! List of options at the "For your own safety" section - ---> UI
  const recommendations = () => {
    return (
      <div className="text-base text-white mb-4 lg:text-xl text-left">
        <ul className="list-disc ml-[17px]">
          {listOptions.map((item) => (
            <li>{t(item)}</li>
          ))}
        </ul>
      </div>
    );
  };

  //! Main UI
  return (
    <div
      className={`text-center lg:text-left max-w-2xl mt-16 lg:mt-0 ${isVn?"font-san":"font-brandon"}`}
    >
    <img
    src={language==="vn"?Vn_stand4strength:stand4strength}
    alt="Stand 4 Strength"
    className="mb-4 lg:mb-12 w-40 lg:w-80 mx-auto lg:mx-0"
  />
      <div
        className={`text-white font-bold mb-14 max-lg:text-3xl lg:text-4px ${
          isVn ? "text-[50px]" : "text-7xl"
        }`}
      >
      {language==="vn"?
      <div>
     <p>{t("manualTest.Title.title1")}</p>
     <p className="whitespace-nowrap mt-4">{t("manualTest.Title.title2")}</p>
      </div>
      :t("manualTest.Title")}
      </div>
      <div className="text-lg lg:text-3xl text-white lg:mb-0">
        <img alt="stan4strength banner" src={banner()} />
      </div>
      <hr className="my-3 lg:my-10" />
      <div className="text-xl text-white mb-4 font-bold uppercase text-left	">
        {t("manualTest.forYourOwnSafety")}
      </div>
      {recommendations()}
    </div>
  );
};

export default LeftPanel;
