import { Box } from "@mui/material";

const Steps = ({ counter }) => {
  const renderStep = (number) => {
    const activeClasses = "text-white border-blueColor bg-blueColor";
    const inactiveClasses = "text-grayColor border-grayColor";
    const inProcessClasses = "text-grayColor border-blueColor";

    return (
      <Box
        className={`w-[45px] h-[45px] rounded-[10px] flex justify-center items-center text-[24px] border-[2px] border-[solid] ${
          number <= counter
            ? activeClasses
            : number === counter + 1
            ? inProcessClasses
            : inactiveClasses
        }`}
      >
        {number}
      </Box>
    );
  };

  return (
    <Box className={`text-[24px] gap-[10px] w-full h-[50%] flex justify-evenly items-center bg-[#ffffff] absolute bottom-0 `}>
      {[1, 2, 3, 4, 5].map((number) => renderStep(number))}
    </Box>
  );
};

export default Steps;
