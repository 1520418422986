import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
  AI_ENABLED_CAMERA,
  CAMERA_FALLBACK,
  Manual_Test,
} from "../../../../utils/constants/internalUrls";
import ReactPlayer from "react-player";
import {
  MUSCLE_CALCULATOR_VIDEO,
  MUSCLE_CALCULATOR_VIDEO_vn,
} from "../../../../utils/constants/externalUrls";
import i18next from "i18next";
//! Start finction
function InstructionsModal({ isManualTest, setCameraAccess }) {
  //! Constants & Hooks
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { language } = i18next;
  //! States
  //! submit button function
  const submitFunction = () => {
    const checkWebcamPermission = async () => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          video: true,
        });
        stream.getTracks().forEach((track) => track.stop());
        // "Sending a message to the iframe.
        window.parent.postMessage("Camera permission granted", "*");
        setCameraAccess(true);
        navigate(AI_ENABLED_CAMERA);
      } catch (error) {
        console.log("error: " + error);
        setCameraAccess(false);
        navigate(CAMERA_FALLBACK);
      }
    };
    isManualTest ? navigate(Manual_Test) : checkWebcamPermission();
  };

  const listOptions = Array.from(
    { length: language === "vn" ? 4 : 5 },
    (_, i) => `manualTest.forYourOwnSafetyList.${i + 1}`
  );

  //! UI Section
  //! Submit button --> UI
  const SubmitButton = () => {
    return (
      <div className="flex items-center me-4 mt-14 gap-5 w-full max-sm:justify-center ">
        <button
          onClick={() => submitFunction()}
          className={`focus:border-blueColor focus:border-2 border-bg-blueColor border-solid py-4 px-16 flex items-center justify-center font-bold text-[20px]
          bg-yellowBtn hover:opacity-95 rounded-lg p-4 text-white mb-4`}
        >
          {t("understand")}
        </button>
      </div>
    );
  };
  const leftContent = () => {
    return (
      <div className="lg:w-[50%] flex flex-col justify-start items-start sm:p-14 max-sm:px-8 h-full">
        <p className="text-white text-[3rem] max-sm:text-[1.5rem] font-bold items-start mb-3">
          {t("safety_notes")}
        </p>
        <ul className="list-disc text-white text-[1.3rem] h-fit">
          {listOptions.map((item) => (
            <li>{t(item)}</li>
          ))}
        </ul>
        {SubmitButton()}
      </div>
    );
  };
  const rightContent = () => {
    return (
      <div className="lg:w-[70%] flex justify-center items-center sm:p-6 max-sm:p-3 w-full max-lg:w-full max-lg:h-[500px] max-md:h-[400px] max-sm:h-[300px]">
        <ReactPlayer
          url={
            language === "vn"
              ? MUSCLE_CALCULATOR_VIDEO_vn
              : MUSCLE_CALCULATOR_VIDEO
          }
          controls={true}
          width="100%"
          height="100%"
        />
      </div>
    );
  };
  //! Main UI
  return (
    <div className="w-full h-full flex justify-center items-center min-h-[100vh] lg:p-14 p-3 max-sm:p-0">
      <div className="w-full h-full flex flex-col-reverse lg:flex-row">
        {leftContent()}
        {rightContent()}
      </div>
    </div>
  );
}

export default InstructionsModal;
