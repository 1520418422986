import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  AI_ENABLED_CAMERA,
  CAMERA_FALLBACK,
  INSTRUCTIONS_MODAL,
  Manual_Test,
} from "../../../utils/constants/internalUrls";
import AgeSelector from "./ageSelector";
import GenderSelector from "./genderSelector";
import { Trans, useTranslation } from "react-i18next";
import arrow from "./../../../assets/Vectors/SVG/ic_arrow_rigth.svg";
import {
  event,
  eventWithParameters,
} from "../../../utils/gaStatistics/gaEvent";
import { Icon } from "@iconify/react";
import i18n from "i18next";
import validateForm, { validateInput } from "../../../utils/formValidation";
import PersonalInformationForm from "./personalInformation";
import { useFormSubmit } from "../../../hooks/useForm";
import { AU_SHARE_DOMAIN } from "../../../utils/constants/externalUrls";

const ClientInfo = ({
  age,
  gender,
  personalInfoForm: form,
  setAge,
  setGender,
  setCameraAccess,
  setManualTest,
  setPersonalInformation,
}) => {
  const { language } = i18n;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isIn = language === "in";
  const [buttonText, setButtonText] = useState(t("muscleAge"));
  const [validInput, setValidInput] = useState(false);
  const [errors, setErrors] = useState({});

  const checkWebcamPermission = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        video: true,
      });
      stream.getTracks().forEach((track) => track.stop());
      // "Sending a message to the iframe.
      window.parent.postMessage("Camera permission granted", "*");
      setCameraAccess(true);
      navigate(AI_ENABLED_CAMERA);
    } catch (error) {
      console.log("error: " + error);
      setCameraAccess(false);
      navigate(CAMERA_FALLBACK);
    }
    setValidInput(true);
    setButtonText("muscleAge");
  };

  const handleSubmissionIsSuccessful = () => {
    setManualTest(false);
    setValidInput(false);
    setButtonText("muscleAgeAccessCamera");
    event("Submission", "Form", "Client Info", 1, false);
    checkWebcamPermission();
  };

  const { mutate: sendUserInfoToApi } = useFormSubmit(
    handleSubmissionIsSuccessful
  );

  useEffect(() => {
    if (gender === "") setValidInput(false);
    else setValidInput(true);
  }, [gender]);

  const manualTestButton = () => {
    setManualTest(true);
    if (language === "au") {
      window.location.href = AU_SHARE_DOMAIN;
    } else {
      navigate(Manual_Test);
    }
  };

  const handleChange = ({ target: input }) => {
    const stateForm = { ...form };
    const inputName = input.name;
    stateForm[inputName] = input.value;
    const stateErrors = validateInput(inputName, input.value);
    setErrors((prevErrors) => ({ ...prevErrors, [inputName]: stateErrors }));
    setPersonalInformation(stateForm);
  };

  //! Navigate to the AI section
  const handleSubmit = () => {
    if (isIn) {
      const stateErrors = validateForm(form);
      setErrors(stateErrors);
      if (Object.values(stateErrors).every((value) => value === "")) {
        sendUserInfoToApi(form);
      }
    } else {
      setManualTest(false);
      setValidInput(false);
      setButtonText("muscleAgeAccessCamera");
      if (language === "th") {
        const parameters = {
          event_action: "click_start_calculator",
          event_label: `page-${window.location.pathname}`,
          gender: gender,
          age: age,
        };
        eventWithParameters("muscle_age_calculator", parameters);
      } else {
        event("Submission", "Form", "Client Info", 1, false);
      }
      if (language === "au" || language === "vn") {
        navigate(INSTRUCTIONS_MODAL);
      } else {
        checkWebcamPermission();
      }
    }
  };

  return (
    <div className="max-w-2xl justify-center items-center flex flex-col w-full">
      <div className="w-full p-4 mt-12 bg-white rounded-lg shadow-lg lg:py-8 lg:px-12  lg:mt-0">
        <div className="flex flex-col">
          {language === "in" && (
            <PersonalInformationForm errors={errors} onChange={handleChange} />
          )}
          <GenderSelector gender={gender} setGender={setGender} />
          <AgeSelector age={age} setAge={setAge} />
        </div>
        <div className="mt-6">
          <button
            tabIndex="0"
            onClick={handleSubmit}
            disabled={!validInput}
            className={`focus:border-blueColor focus:border-2 border-bg-blueColor border-solid py-4 px-4 flex items-center justify-center gap-[20px] ${
              !validInput
                ? "bg-grayColor cursor-not-allowed "
                : "bg-blueColor hover:opacity-95 "
            } w-full rounded-lg p-4 text-white z-20 hover:bg-opacity-90 mb-4 `}
          >
            <span className=" text-[25px]">{t(`homePage.${buttonText}`)}</span>
            <img src={arrow} alt="arrow" />
          </button>
        </div>
        <hr className="my-4 lg:my-8" />
        <div className="flex flex-col">
          <div className="mb-3 text-xs lg:text-sm">{t("calculation")}</div>
          {language !== "au" && (
            <div className="text-xs lg:text-sm">{t("reference")}</div>
          )}
        </div>
      </div>
      {language === "vn" ? (
        <button
          tabIndex="0"
          onClick={() => navigate("/manualTest")}
          className="flex items-center justify-center gap-[5px] rounded-lg text-white z-20 mt-7 bg-yellowBtn p-2 pr-3"
        >
          <Icon icon="mdi:hand-tap" color="white" width="30" />
          <span className="text-base">{t("manualTest.manualbutton")}</span>
        </button>
      ) : (
        <div className="text-white mt-7">
          <span className="leading-none lg:text-[16px] max-md:text-[18px] ">
            <Trans
              i18nKey={"manualTest.manualbutton"}
              components={{
                bold: (
                  <span
                    onClick={manualTestButton}
                    className="leading-normal font-bold underline underline-offset-[8px] cursor-pointer block w-fit mt-1 lg:text-[22px] max-md:text-[18px]"
                  />
                ),
              }}
            />
          </span>
        </div>
      )}
    </div>
  );
};

export default ClientInfo;
