const inTranslation = {
  translation: {
    results: {
      shareResults: "Share your results",
      utterGood:
        "Great job! Looks like your muscle age is younger than your age",
      utterBad:
        "Oh Dear! Looks like your muscle age is older than your age. It's time to focus on building your muscle mass and strength.",
      utterOnPar:
        "Great job! Looks like your muscle age is on par with your age",

      output: "Muscle age calculator output: ",
      footNoteFaster75:
        "Your result is faster than 75% of your peers between the {{actualAge}} age range.",
      footNoteOnPar:
        "Your result is within 25%-75% of your peers between the {{actualAge}} age range.",
      footNoteSlower75:
        "Your result is slower than 75% of your peers between the {{actualAge}} age range.",

      footNoteFaster80:
        "Your result is faster than 75% of your peers aged 80+.",
      footNoteOnPar80: "Your result is within 25%-75% of your peers aged 80+.",
      footNoteSlower80:
        "Your result is slower than 75% of your peers aged 80+.",

      percentileBelow25:
        "Your result is below the 25th percentile for the age category of 40-44.",
      percentileAbove25:
        "Your result is equal or above the 25th percentile for the age category of {{outputAge}}.",
      percentileBelow75:
        "Your result is equal or below the 75th percentile for the age category of {{outputAge}}.",

      unsupportedGroup: "unsupported group",
      invalidLinkOrUnsupportedProcess:
        "This link is broken or the process is incorrect, please try again!",
      year: " years old",
      understand: "I understand",
      safety_notes: "Safety Notes",
    },
  },
};

export default inTranslation;
