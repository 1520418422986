import React from "react";
import { BrowserRouter } from "react-router-dom";
import { CssBaseline } from "@mui/material";
import ".././App.scss";
import Layout from "./Layout";
import PWAInitailizer from "../utils/PWA";
import ReduxProvider from "../providers/reduxProvider";
import ReactQueryProvider from "../providers/reactQueryProvider";

const App = () => {
  return (
    <ReduxProvider>
      <ReactQueryProvider>
        <CssBaseline />
        <BrowserRouter>
          <PWAInitailizer />
          <Layout />
        </BrowserRouter>
      </ReactQueryProvider>
    </ReduxProvider>
  );
};

export default App;
