import { CAMERA_FALLBACK } from "../../utils/constants/internalUrls";
import usePageTracking from "../../hooks/useTracking";
import LeftPanel from "./leftPanel";
import RightPanel from "./rightPanel";

const CameraFallback = () => {
  usePageTracking(CAMERA_FALLBACK);
  return (
    <>
      <div className="landing">
        <div className="container mx-auto">
          <div className="p-6 flex flex-col lg:p-0 lg:flex-row lg:justify-between items-center lg:mx-[40px]">
            <LeftPanel />
            <RightPanel />
          </div>
        </div>
      </div>
    </>
  );
};

export default CameraFallback;
