/*! **********************************************************************************************
 * This is the i18next translation file for the German language.
 * The file exports an object containing key-value pairs for the German translations of the application.
 * The translations include text for the home page, the SelectAge component, and the Assist component.
 * This object contains the German translations for the same keys as in the English translation file.
 * Abbott Copywrite 2023
 ************************************************************************************************/
const frTranslation = {
  translation: {
    iosAlert:
      'ผู้ใช้ iOS และ Mac ควรไปที่ส่วน "การตั้งค่า" ของเบราว์เซอร์ของพวกเขาแล้วแตะตัวเลือก "เพิ่มลงในหน้าจอหลัก" เพื่อติดตั้งซอฟต์แวร์',
    installDescription:
      "ติดตั้งแอปบนอุปกรณ์ของคุณเพื่อเข้าถึงได้ง่ายตลอดเวลา ไม่มี App Store ไม่ต้องดาวน์โหลด ไม่ยุ่งยาก",

    homePage: {
      muscleAgeTitle: "เช็กอายุกล้ามเนื้อของคุณได้แล้ว วันนี้",
      exercise:
        "ซึ่งจะช่วยให้คุณทราบว่าอายุกล้ามเนื้อของคุณเป็นอย่างไรในขณะนี้",
      selectYourAge: "เลือกอายุของคุณ",
      atrophy:
        "หลังจากอายุ 40 ปี คุณอาจสูญเสียมวลกล้ามเนื้อได้มากถึง 15% ทุกทศวรรษ<superScript>[1]</superScript>.",
      selectGender: "เลือกเพศของคุณ",
      male: "ชาย",
      female: "หญิง",
      muscleAge: "เริ่มเช็กอายุกล้ามเนื้อ",
      limitation: "แนะนำสำหรับผู้ที่อายุ 40 ปีขึ้นไป",
      muscleAgeAccessCamera: "การเข้าถึงกล้อง...",
      startYourMuscleAge:
        "สิ่งสำคัญคือต้องติดตามและรักษาความแข็งแรงของกล้ามเนื้อของคุณเพื่อป้องกันการล้มและดำเนินชีวิตที่แข็งแรงและกระตือรือร้นต่อไป ด้วยเหตุนี้เราจึงพัฒนา การทดสอบยืนขึ้นจากเก้าอี้ 5 ครั้ง - ขึ้นอยู่กับการทดสอบ การทดสอบลุกขึ้นจากเก้าอี้ 5 ครั้ง เพื่อช่วยให้แสดงถึงความแข็งแรงและประสิทธิภาพทางกายภาพของขาของคุณ การประชุมสดแบบดิจิทัลที่สร้างสรรค์ได้รับการออกแบบมาเพื่อใช้กล้องคอมพิวเตอร์ของคุณเพื่อช่วยให้คุณผ่าน การทดสอบยืนขึ้นจากเก้าอี้ 5 ครั้ง และกำหนดอายุกล้ามเนื้อของคุณ",
    },

    cameraFallback: {
      getStartExplain:
        "การมีกล้ามเนื้อที่แข็งแรง  จะทำให้คุณใช้ชีวิตได้อย่างมีความสุข เมื่ออายุเข้าสู่เลข 4 มวลกล้ามเนื้อจะหายไปประมาณ 15% ในทุกๆ 10 ปี เครื่องคำนวณอายุกล้ามเนื้อ อ้างอิงจากการทดสอบลุกนั่ง 5 ครั้ง ซึ่งสามารถใช้ประเมินความแข็งแรงของกล้ามเนื้อขาได้ เช็กอายุกล้ามเนื้อของคุณได้แล้ว วันนี้",
      camera: "หากคุณไม่มีกล้อง สามารถเช็กได้ด้วยวิธีนี้",
      encouraging:
        "จับเวลาด้วยตัวคุณเอง และกรอกเวลาที่ได้ในแบบฟอร์มเพื่อคำนวนอายุกล้ามเนื้อ",
      motivating: "เตรียมพร้อมสำหรับความท้าทาย",
      completeTestRep: "ทำแบบทดสอบซ้ำ",
      explanatory:
        "เพื่อทำการทดสอบเก้าอี้ยืนขึ้นได้ คุณจะต้องหาเก้าอี้ที่คุณสามารถนั่งและยืนขึ้นได้",
      stepByStep:
        "เพื่อทำการทดสอบการนั่งที่นั่งซ้ำ โดยพับแขนข้างอก คุณจำเป็นต้องนั่งบนเก้าอี้และยืนขึ้นให้เร็วที่สุดที่คุณสามารถทำได้เพื่อทำการซ้ำ 5 ครั้ง",
      instructive:
        "เมื่อคุณพร้อมแล้ว ให้จับเวลาว่าคุณสามารถทำการเดินที่นั่ง 5 ครั้งได้เร็วขนาดไหน แล้วเมื่อคุณทำเสร็จแล้ว ให้กรอกเวลาทั้งหมดของคุณด้านล่างเพื่อหาอายุกล้ามเนื้อของคุณ",
      inputTestTime: "ใส่เวลาที่คุณจับเวลาได้",
      yourTime: "เวลาทดสอบของคุณ",
      calculateMuscle: "เริ่มคำนวณอายุกล้ามเนื้อ",
      startChallenge: "เริ่มความท้าทาย",
    },

    userPreparation: {
      wait: "กรุณารอสักครู่ในขณะที่กำลังโหลดโมเดล AI...",
      chair:
        "วางเก้าอี้หันหน้าเข้าหากล้อง เก้าอี้ควรห่างจากหน้าคอมพิวเตอร์ประมาณ 1 เมตร",
      nextStep: "ถัดไป",
      notVisibleKnees: "ตรวจสอบให้แน่ใจว่าหัวเข่าของคุณมองเห็นได้!",
      description: "ดูวิดีโอวิธีการเช็กอายุกล้ามเนื้อ",
      startTheChairStandTest: "เริ่มเช็กอายุกล้ามเนื้อ",
    },

    SquatTest: {
      1: "ยอดเยี่ยม! ซ้ำอีก 4 ครั้ง!",
      2: "",
      3: "เยี่ยมมาก! คุณเกือบจะถึงครึ่งทางแล้ว!",
      attach: "เหลืออีก 2 รอบเท่านั้น",
      4: "รอบสุดท้ายแล้ว",
      default:
        "ยืนและนั่งให้เร็วที่สุด 5 ครั้ง เพื่อเริ่มการเช็กอายุกล้ามเนื้อของคุณ",
    },

    testCompletionMessage: {
      congratulation: "การเช็กอายุกล้ามเนื้อแล้ว!",
      processing: "ระบบกำลังคำนวณอายุกล้ามเนื้อของคุณ",
    },

    results: {
      shareResults: "แชร์ผลลัพธ์ของคุณ",
      utterGood:
        "กล้ามเนื้อของคุณยังแข็งแรงอยู่! อายุกล้ามเนื้อของคุณน้อยกว่าอายุคุณ",
      utterBad: `คุณต้องเริ่มดูแลกล้ามเนื้อแล้วล่ะ!
        อายุกล้ามเนื้อของคุณมากกว่าอายุคุณ`,
      utterOnPar:
        "กล้ามเนื้อของคุณยังแข็งแรงอยู่! อายุกล้ามเนื้อของคุณเท่ากับอายุคุณ",
      output: "กล้ามเนื้อของคุณอยู่ในช่วงอายุ: ",
      footNoteFaster75:
        "ผลการทดสอบของคุณเร็วกว่า 75% ของเพื่อนร่วมวัยในช่วงอายุ {{actualAge}} ปี.",
      footNoteSlower75:
        "ผลการทดสอบของคุณช้ากว่า 75% ของเพื่อนร่วมวัยในช่วงอายุ {{actualAge}} ปี",
      footNoteOnPar:
        "ผลการทดสอบของคุณอยู่ในช่วง 25%-75% ของเพื่อนร่วมวัยในช่วงอายุ {{actualAge}}",
      percentileBelow25:
        "ผลการทดสอบของคุณอยู่ใต้เกณฑ์ที่ 25% สำหรับกลุ่มอายุ 40-44 ปี",
      percentileAbove25:
        "ผลการทดสอบของคุณอยู่ในระดับที่เท่ากับหรือสูงกว่า 25% สำหรับกลุ่มอายุ {{outputAge}}",
      percentileBelow75:
        "ผลการทดสอบของคุณเท่ากับหรือต่ำกว่าร้อยละ 75 สำหรับกลุ่มอายุ {{outputAge}}",
      unsupportedGroup: "กลุ่มที่ไม่สนับสนุน",
      invalidLinkOrUnsupportedProcess:
        "กรุณาลองอีกครั้ง เนื่องจากลิงก์นี้ไม่ทำงานหรือขั้นตอนไม่ถูกต้อง",
      year: "ปี",
    },
    manualTest: {
      manualbutton:
        "ไม่มีกล้อง? ไม่เป็นไร! คุณสามารถจับเวลาตัวเองขณะทำ การทดสอบ และป้อนเวลาของคุณลงในเครื่องคิดเลขด้วยตนเอง **<bold> เข้าร่วมการแข่งขันโดยไม่ต้องใช้กล้องที่นี่</bold>",
      EnterDetails:
        "ป้อนรายละเอียดของคุณลงในเครื่องคำนวณอายุกล้ามเนื้อด้านล่าง",
      Step3: "ขั้นตอนที่ 3",
      age: "อายุ",
      gender: "เพศ",
      timeInSeconds: "เวลา (วินาที)*",
      submit: "ส่ง",
      sitToStand: "เวลานั่งถึงยืน (เช่น 7.5 วินาที)",
      Title: "ทำแบบทดสอบของเราเพื่อหาอายุกล้ามเนื้อของคุณตอนนี้",
      forYourOwnSafety: "เพื่อความปลอดภัยของตัวคุณเอง",
      forYourOwnSafetyList: {
        1: "ระวังอย่าเกร็งหรือบาดเจ็บ",
        2: "หยุดความท้าทายทันทีหากหัวเข่าของคุณเริ่มเจ็บ",
        3: "อย่าเอนหลังเพื่อเพิ่มแรงผลักดันหรือคุณอาจล้มลง",
        4: "ทำแบบทดสอบเมื่อคุณมีเพื่อนหรือสมาชิกในครอบครัวอยู่ใกล้ ๆ เพื่อสนับสนุนคุณ",
        5: "อย่าลองทำแบบทดสอบหากคุณเพิ่งล้มหรือบาดเจ็บ",
      },
    },

    didYouKnow: "คุณรู้หรือไม่?",
    calculation:
      "การคำนวณอายุกล้ามเนื้อนี้เป็นเครื่องมือที่ใช้ในการประเมินอ้างอิงจากการทดสอบการลุกนั่ง 5 ครั้ง ซึ่งเป็นตัวช่วยบ่งชี้เกี่ยวกับความแข็งแกร่งและประสิทธิภาพทางกายภาพของกล้ามเนื้อในส่วนล่างของลำตัว",
    reference: "[1] TH.2023.41242.ENS.1 (v1.0) ©2023Abbott",
    understand: "I understand",
    safety_notes: "Safety Notes",
  },
};

export default frTranslation;
