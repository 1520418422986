import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { MUSCLE_AGE_RESULTS } from "../../../utils/constants/internalUrls";
import { useNavigate } from "react-router-dom";
import ArrowRigth from "./../../../assets/Vectors/SVG/ic_arrow_rigth.svg";
import AgeSelector from "../../homePage/clientInfo/ageSelector";
import GenderSelector from "../../homePage/clientInfo/genderSelector";
import arrowDown from "./../../../assets/Vectors/SVG/ic_down_right_Arrow.svg";
//! Start component
const RightPanel = ({ setAge, setGender, setTime, age, gender,time }) => {

  //! States
  const [validInput, setValidInput] = useState(false); // Indicates whether all required inputs have been filled

  //! Hooks
  const { t, i18n } = useTranslation();
  const { language } = i18n;
  const isVn = language === "vn";
  const navigate = useNavigate();

  //! Active submit button
  useEffect(() => {
    setValidInput(age && gender && time);
  }, [time, gender, age]);

  function getLanguageClassName() {
    return isVn ? "font-san" : "font-brandon";
  }

  //! Submit button
  const calculateMuscleAge = () => {
    time && navigate(MUSCLE_AGE_RESULTS);
  };

  //! UI section

  //! The title of inputs
  const headerUI = () => {
    return (
      <div className="font-extrabold text-2xl flex flex-col items-start w-full text-[#01446D] mb-5">
        {language === "au" && <p>{t("manualTest.Step3")}</p>}
        <p>{t("manualTest.EnterDetails")}</p>
      </div>
    );
  };

  //! DropDown UI
  const dropdownUI = () => {
    return (
      <div className="flex flex-col items-start w-full">
        <GenderSelector gender={gender} setGender={setGender} />
        <AgeSelector age={age} setAge={setAge} />
      </div>
    );
  };

  //! Inputs
  const inputs = () => {
    return (
      <div
        className={`flex w-full flex-col gap-5 justify-start items-start ${
          !isVn && "font-brandon"
        }`}
      >
        <div className="flex">
          <img className="w-7" src={arrowDown} alt="arrow"/>
          <p className={`lg:text-2xl ${isVn ? "font-medium" : "font-bold"}`}>
            {t("manualTest.sitToStand")}
          </p>
        </div>
        <input
          onChange={(e) => setTime(e.target.value)}
          placeholder={t("manualTest.timeInSeconds")}
          className="p-4 border-solid border-gray-300 border-[1px] font-medium rounded-[4px] w-full h-14 placeholder:text-[19px] placeholder:font-brandon placeholder:text-gray-600"
          type="number"
        />
      </div>
    );
  };

  //! Button
  const submitButton = () => {
    return (
      <>
        <button
          tabIndex="0"
          onClick={() => calculateMuscleAge()}
          disabled={!validInput}
          className={`focus:border-blueColor w-full focus:border-2 border-bg-blueColor border-solid py-4 px-4 flex items-center justify-center gap-[20px] mt-5 ${
            !validInput
              ? "bg-grayColor cursor-not-allowed "
              : "bg-blueColor hover:opacity-95 "
          } w-full rounded-lg p-4 text-white z-20 hover:bg-opacity-90 mb-4 `}
        >
          <span className=" text-[25px]">{t(`manualTest.submit`)}</span>
          <img src={ArrowRigth} alt="arrow" />
        </button>
        <hr class="my-4 lg:my-2  w-full" />
        <div className="flex flex-col">
          <div className="text-xs lg:text-sm mb-4 ">{t("calculation")}</div>
          <div className="text-xs lg:text-sm ">{t("reference")}</div>
        </div>
      </>
    );
  };

  //! Main UI
  return (
    <div
      className={`flex justify-between items-center flex-col bg-white lg:h-fit max-w-[670px] font-brandon  p-8 py-12 rounded-lg gap-3 ${getLanguageClassName()}`}
    >
      {headerUI()}
      {dropdownUI()}
      {inputs()}
      {submitButton()}
    </div>
  );
};

export default RightPanel;
