import LeftPanel from "./leftPanel";
import RightPanel from "./rightPanel";

//! Start component
const ManualTest = () => {
  return (
    <div className="w-full h-full p-3 flex flex-col lg:flex-row justify-center items-center gap-14 min-h-[100vh]">
      <LeftPanel />
      <RightPanel />
    </div>
  );
};

export default ManualTest;
