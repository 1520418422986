import i18next from "i18next";
import { useTranslation } from "react-i18next";
import {
  AU_SHARE_DOMAIN,
  DEFAULT_DOMAIN,
  MY_SHARE_DOMAIN,
} from "../../../utils/constants/externalUrls";

const ShareButton = ({ Platform, onClick, output, children }) => {
  const { t } = useTranslation();
  const { language } = i18next;

  const shareUrl =
    language === "my"
      ? MY_SHARE_DOMAIN
      : language === "au"
      ? AU_SHARE_DOMAIN
      : DEFAULT_DOMAIN;

  return (
    <Platform
      title={`${t("results.output")}, ${output.outputAge}, ${t("results.year")},
               ${
                 output.percentileResult &&
                 t(
                   `results.${output.percentileResult?.message}`,
                   output.percentileResult?.outputAge && {
                     outputAge: output.percentileResult?.outputAge,
                   }
                 )
               }}
               `}
      url={shareUrl}
      onClick={onClick}
    >
      {children}
    </Platform>
  );
};

export default ShareButton;
