const viTranslation = {
  translation: {
    iosAlert:
      'Người dùng iOS và Mac nên truy cập phần "Cài đặt" của trình duyệt của họ và nhấn vào tùy chọn "Thêm vào màn hình chính" để cài đặt phần mềm.',
    installDescription:
      "Pasang aplikasi ke peranti anda untuk mengaksesnya dengan mudah pada bila-bila masa. Tiada kedai aplikasi. Tiada muat turun. Tiada masalah.",

    homePage: {
      muscleAgeTitle: {
        title1: "Khám phá ngay",
        title2: "tuổi khối cơ của bạn",
      },
      exercise: "",
      atrophy:
        "Sau 40 tuổi, bạn có thể mất tới 15% khối cơ sau mỗi 10 năm đấy! Hãy dành 3 phút tham gia Thử thách với chiếc ghế để xem tuổi khối cơ của bạn là bao nhiêu nhé!",
      selectYourAge: "Hãy chọn tuổi của bạn!",
      selectGender: "Hãy chọn giới tính của bạn!",
      male: "Nam",
      female: "Nữ",
      muscleAge: "Kiểm tra bằng camera ngay",
      limitation: "Công cụ này phù hợp cho người trên 40 tuổ",
      muscleAgeAccessCamera: "Truy cập vào Camera...",
    },

    cameraFallback: {
      camera: "Không có camera cũng không sao cả!",
      encouraging:
        "Khi bạn hoàn tất, hãy nhập tổng thời gian của bạn vào bên dưới để biết tuổi cơ bắp của bạn!",
      getStartExplain:
        "Sau 40 tuổi, bạn có thể mất tới 15% khối cơ sau mỗi 10 năm!",
      motivating: "Bạn đã sẵn sàng cho thử thách này chưa ?",
      completeTestRep: "Hãy hoàn thành bài kiểm tra lặp lại sau đây",
      explanatory:
        "Để hoàn thành thử thách chiếc ghế này, bạn sẽ phải cần tìm một chiếc ghế để thực hiện bài tập đứng lên và ngồi xuống",
      stepByStep:
        "Để thực hiện lặp lại động tác Đứng lên từ ghế, bạn cần ngồi trên ghế, khoanh tay trước ngực và đứng dậy nhanh nhất có thể để hoàn thành 5 lần lặp lại.",
      instructive:
        "Khi bạn đã sẵn sàng, hãy tính thời gian bạn có thể hoàn thành 5 lần lặp lại Đứng lên từ ghế. Khi bạn hoàn tất, hãy nhập tổng thời gian thực hiện để biết tuổi khối cơ của bạn",
      inputTestTime:
        "Nhập tổng thời gian tham gia thử thách của bạn vào bên dưới (giây)",
      yourTime: "Thời gian hoàn thành của bạn là",
      calculateMuscle: "Đoán xem tuổi khối cơ của bạn?",
      startChallenge: "Bắt đầu thử thách!",
    },

    userPreparation: {
      chair: "Đặt ghế cách camera 1 mét",
      wait: "Mô hình AI đang tải, xin vui lòng chờ!",
      notVisibleKnees:
        "Lưu ý: Hãy đảm bảo bạn có thể nhìn thấy <bold>đầu gối và vai</bold> của mình trong khung hình!",
      nextStep: "NHẤN ĐỂ TIẾP TỤC",
      description:
        "Nhấn để xem video hướng dẫn thực hiện Thử thách với chiếc ghế nhé!",
      startTheChairStandTest: "BẮT ĐẦU THỬ THÁCH",
    },

    SquatTest: {
      1: "Tuyệt vời! Còn 4 lần nữa!",
      2: "Tốt lắm!",
      3: "Hoàn hảo! Bạn đã đi được gần nửa chặng đường rồi!",
      attach: "Còn 2 hai lần nữa thôi!",
      4: "Lần cuối nào!",
      default: "Hoàn thành 5 lần lặp lại nhanh nhất có thể!",
    },

    testCompletionMessage: {
      congratulation: "Chúc mừng bạn đã hoàn thành thử thách!",
      processing: "Nào hãy cùng đoán xem tuổi khối cơ của bạn là…",
    },

    results: {
      shareResults: "Chia sẻ kết quả của bạn",
      utterGood:
        "Tuyệt! Có vẻ như tuổi khối cơ của bạn trẻ khỏe hơn tuổi thật của bạn",
      utterBad:
        "Có vẻ như tuổi khối cơ của bạn già hơn so với tuổi của bạn. Đã đến lúc tập trung tăng cường sức khỏe khối cơ hơn rồi!",
      utterOnPar:
        " Tuyệt! Có vẻ như tuổi cơ của bạn ngang bằng với tuổi của bạn.",
      output: "Kết quả đánh giá tuổi khối cơ: ",
      footNoteFaster75:
        "Kết quả của bạn nhanh hơn 75% so với những người cùng lứa tuổi trong khoảng tuổi {{actualAge}}.",
      footNoteSlower75:
        "Kết quả của bạn chậm hơn 75% so với những người cùng lứa tuổi trong khoảng tuổi {{actualAge}}.",
      footNoteOnPar:
        "Kết quả của bạn nằm trong khoảng từ 25% đến 75% so với những người cùng lứa tuổi trong khoảng tuổi {{actualAge}}.",
      percentileBelow25:
        "Kết quả của bạn nằm dưới khoảng 25% những người thuộc nhóm tuổi 40-44.",
      percentileAbove25:
        "Kết quả của bạn bằng hoặc vượt trên phần trăm 25 cho nhóm tuổi {{outputAge}}.",
      percentileBelow75:
        "Kết quả của bạn bằng hoặc dưới phần trăm 75 cho nhóm tuổi {{outputAge}}.",
      unsupportedGroup: "Nhóm không được hỗ trợ.",
      invalidLinkOrUnsupportedProcess:
        "Liên kết này bị hỏng hoặc quá trình không chính xác, vui lòng thử lại!",
      year: " tuổi",
    },
    manualTest: {
      manualbutton: "Kiểm tra nhanh",
      EnterDetails: "Nhập thông tin của bạn sau khi thực hiện dưới đây",
      Step3: "Bước 3",
      age: "Tuổi",
      gender: "Giới tính",
      timeInSeconds: "Thời gian (giây)*",
      submit: "NỘP",
      sitToStand: "Thời gian thực hiện (ví dụ: 7,5 giây)",
      Title: {
        title1: "KHÁM PHÁ NGAY",
        title2: "TUỔI KHỐI CƠ CỦA BẠN",
      },
      forYourOwnSafety: "LƯU Ý:",
      forYourOwnSafetyList: {
        1: "Cẩn thận để không xảy ra bong gân, đau cơ hay bất kỳ chấn thương nào khác",
        2: "Dừng lại ngay nếu cảm thấy đau đầu gối hoặc đau lưng",
        3: "Không ngả người lên ghế để lấy đà, bạn sẽ dễ bị lật người về sau",
        4: "Nên thực hiện bài tập khi người than hoặc bạn bè có mặt để hỗ trợ ",
      },
    },

    calculation:
      "Công cụ tính tuổi khối cơ được dựa trên tham chiếu của bài kiểm tra đứng lên ngồi xuống với ghế 5 lần. Bài tập này có thể kiểm tra được sức khỏe và hoạt động thể chất của các chi dưới.",
    reference:
      "[1] Baier S et al.JPEN 2009; 33:71-82. Based on lean body mass (LBM) measurements",
    didYouKnow: "Bạn có biết?",
    understand: "BẮT ĐẦU",
    safety_notes: "HƯỚNG DẪN AN TOÀN:",
  },
};

export default viTranslation;
