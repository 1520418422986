/***********************************************
 * Function: pageview & event
 * Description: Google Analytics with information such as the time spent on the page and the page URL.

 *
 * Copyright:
 * (c) Abbott 2023
 **********************************************/

export const event = (action, category, label, value, nonIntraction) => {
  window.gtag("event", action, {
    event_category: category,
    event_label: label,
    value: value,
    dimension1: "ABBOTT_IFRAME",
    non_intraction: nonIntraction,
  });
};

export const eventWithParameters = (eventName, eventParameters) => {
  window.gtag("event", eventName, eventParameters);
};
