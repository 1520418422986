const enTranslation = {
  translation: {
    iosAlert:
      "IOS and Mac users should navigate to the 'Settings' section of their browser and tap on the 'Add to Home Screen' option to install the software.",
    installDescription:
      "Install the app on your device to easily access it anytime. No app store. No download. No hassle.",
    homePage: {
      muscleAgeTitle: "Discover your muscle age",
      exercise:
        "Take the 1 minute Chair Stand Challenge to discover your muscle age now!",
      atrophy:
        "After the age of 40, you can lose up to 15% of muscle mass per decade<superScript>[1]</superScript>.",
      personalInformation: "Personal Information",
      selectYourAge: "Select your age",
      selectGender: "Select your gender",
      male: "Male",
      female: "Female",
      firstName: "First name",
      lastName: "Last name",
      emailAddress: "Email address",
      mobilePhone: "Mobile phone",
      muscleAge: "See Your Muscle Age",
      limitation:
        "This tool is only applicable for individuals over 40 years of age",
      muscleAgeAccessCamera: "Accessing the Camera...",
      startYourMuscleAge:
        "It is important to monitor and maintain your muscle strength to prevent falls and to continue living a healthy and active life. That's why we developed the Chair Stand Challenge - based on the 5-time Chair to Stand test, to help indicate the strength and physical performance of your lower limbs. The innovative digital Live Session is designed to use your computer's camera to help guide you through the Challenge and determine your muscle age.",
    },

    errors: {
      requiredField: "$t(homePage.{{fieldName}}) is required",
      invalidField: "Enter a valid $t(homePage.{{fieldName}})",
    },

    cameraFallback: {
      camera: "No Camera? No Problem!",
      encouraging:
        "Time yourself and input your test time below when you're finished to get your muscle age!",
      getStartExplain:
        "After the age of 40, you can lose up to 15% of muscle mass every 10 years. Take 3 minutes with our Chair Stand test to learn what your muscle age is.",
      motivating: "Prepare for the Challenge",
      completeTestRep: "Complete a Test Repetition",
      explanatory:
        "To complete the Chair Stand Challenge, you will need to find a chair you can use to sit down and stand up in.",
      stepByStep:
        "To perform a Chair Stand repetition, with your arms folded across your chest, you need to sit in the chair and stand up as fast as you can to complete the 5 repetitions.",
      instructive:
        "When you're ready, time how quickly you can complete the 5 Chair Stand repetitions. When you're done, input your total time below to get your muscle age!",
      inputTestTime: "Input your test time (Your test time in seconds)",
      yourTime: "Your test time",
      calculateMuscle: "Calculate My Muscle Age",
      startChallenge: "Start the Challenge",
    },

    userPreparation: {
      chair: "Place a chair 1 meter in front of the camera",
      wait: "Please wait while the AI Model is loading...",
      notVisibleKnees:
        "Make sure your <bold>knees & shoulders </bold> are visible!",
      nextStep: "Continue to Next Step",
      description: "Watch the Chair Stand test instruction video",
      startTheChairStandTest: "Start the Chair Stand test",
    },

    SquatTest: {
      1: "Great! 4 more repetitions to go!",
      2: "",
      3: "Perfect! You're almost halfway there!",
      attach: "Only 2 more to go!",
      4: "Last one!",
      default: "Complete 5 chair stand reps to see your muscle age!",
    },

    testCompletionMessage: {
      congratulation: "You completed the Chair Stand test!",
      processing: "Now calculating your Muscle Age...",
    },

    results: {
      output: "Your Muscle Age: ",
      year: " s",
      shareResults: "Share your results",
      unsupportedGroup: "unsupported group",
      invalidLinkOrUnsupportedProcess:
        "This link is broken or the process is incorrect, please try again!",

      utterGoodTipTop: "Tip-top! You are at the PRIME of your strength!",
      utterGood: "You are at the PRIME of your strength!",
      utterBad:
        "OOPS! You are many miles away from being at your PRIME strength!",
      utterOnPar:
        "OOPS! You are a step away from being at your PRIME strength!",

      footNoteOnPar: "Your strength is at the mean for your age.",
      footNoteFaster:
        "Your strength is the mean for the {{outputAge}}s ({{outputAgeRange}}) and also stronger than the mean for your age ({{actualAge}})!",
      footNoteOnPeriod:
        "Your strength is at the mean strength for the {{outputAge}}s ({{outputAgeRange}}) You are also weaker than the mean for your age ({{actualAge}})!",
      footNoteSlower:
        "Your strength is at the mean strength for the {{outputAge}}s ({{outputAgeRange}}) You are also weaker than the mean for your age  ({{actualAge}})!",
      footNoteSlower80:
        "Your strength is weaker than the mean strength for your age +80!",
    },

    manualTest: {
      manualbutton:
        "No Camera? No problem! You can time yourself doing the Chair Stand Challenge and input your time into the calculator manually.<bold> Participate in the challenge without a camera here</bold>",
      EnterDetails: "Enter your details in the muscle age calculator below.",
      Step3: "Step 3",
      age: "Age",
      gender: "Gender",
      timeInSeconds: "Time in Seconds*",
      submit: "SUBMIT",
      sitToStand: "Sit-to-Stand Time(e.g.7.5 seconds)",
      Title: "Take our test to find out your muscle age now",
      forYourOwnSafety: "For Your own safety",
      forYourOwnSafetyList: {
        1: "Be careful not to strain or injure yourself.",
        2: "Stop challenge immediately if your knees start to hurt.",
        3: "Don't lean back to gain momentum or you may topple backwards.",
        4: "Do the test when you have a friend or family member around to support you.",
        5: "Do not try the test if you have a recent fall or injury.",
      },
    },

    calculation:
      "The muscle age calculator is based on the reference of the 5-time chair stand test that can be an indication of the strength and physical performance of the muscles in the lower limbs.",
    reference:
      "[1] Baier S et al.JPEN 2009; 33:71-82. Based on lean body mass (LBM) measurements",
    didYouKnow: "Did you know?",
    understand: "I understand",
    safety_notes: "Safety Notes",
  },
};

export default enTranslation;
